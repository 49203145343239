// extracted by mini-css-extract-plugin
export var activeHeading = "landscape-module--active-heading--sLijM";
export var button = "landscape-module--button--p5O63";
export var chevronDown = "landscape-module--chevron-down--reexJ";
export var description = "landscape-module--description--Nb1Ci";
export var header = "landscape-module--header--CcJah";
export var headerContent = "landscape-module--header-content--Ydgyv";
export var heading = "landscape-module--heading--9j4WU";
export var headingImg = "landscape-module--heading-img--mqaII";
export var headingImgWrapper = "landscape-module--heading-img-wrapper--k9Pkw";
export var headingWrapper = "landscape-module--heading-wrapper--yntxs";
export var landscape = "landscape-module--landscape--IK6fH";
export var landscapeContent = "landscape-module--landscape-content--W7QZz";