// extracted by mini-css-extract-plugin
export var accent = "layout-module--accent--q3mQD";
export var bottomContent = "layout-module--bottom-content--904rP";
export var bottomContentWrapper = "layout-module--bottom-content-wrapper--LKw48";
export var buildingContent = "layout-module--building-content--faKa3";
export var buildingContentWrapper = "layout-module--building-content-wrapper--O6XMx";
export var button = "layout-module--button--Nrjvr";
export var buttonDark = "layout-module--button-dark--a0WeU";
export var buttonWrapper = "layout-module--button-wrapper--buv4m";
export var chevronDown = "layout-module--chevron-down--9tJr3";
export var clearFix = "layout-module--clear-fix--cKtha";
export var column = "layout-module--column--kVJ9y";
export var columnContent = "layout-module--columnContent--9YyP5";
export var columnWrapper = "layout-module--columnWrapper--b2CiT";
export var container = "layout-module--container--eLBMS";
export var downLink = "layout-module--down-link--v4bi3";
export var downloadButton = "layout-module--download-button--STp96";
export var downloadLink = "layout-module--download-link--D0Ug1";
export var flexPanel = "layout-module--flex-panel--nUZo9";
export var footer = "layout-module--footer--rX0Dt";
export var footerContent = "layout-module--footer-content--fVTks";
export var goingColumn = "layout-module--going-column--yq5AP";
export var goingColumnContent = "layout-module--going-column-content--Lk-A4";
export var goingColumnWrapper = "layout-module--going-column-wrapper--etjY9";
export var goingContent = "layout-module--going-content--fawOl";
export var goingContentWrapper = "layout-module--going-content-wrapper--v0S-O";
export var harmsColumn = "layout-module--harms-column--KoDXB";
export var harmsColumnContent = "layout-module--harms-column-content--mg2HF";
export var harmsColumnWrapper = "layout-module--harms-column-wrapper--LF8AA";
export var harmsIcon = "layout-module--harms-icon--Aap3C";
export var introText = "layout-module--intro-text--iWNH5";
export var logo = "layout-module--logo---3dvO";
export var moreButton = "layout-module--more-button--xYO4V";
export var moreImage = "layout-module--more-image--mOBVG";
export var panel = "layout-module--panel--YmMIm";
export var paperContent = "layout-module--paper-content--hMAE1";
export var paperContentTitleWrapper = "layout-module--paper-content-title-wrapper--0kFw-";
export var paperPanel = "layout-module--paper-panel--SqRmH";
export var paperPanelContent = "layout-module--paper-panel-content--RyF1V";
export var paperPanelLast = "layout-module--paper-panel-last--haC8n";
export var paperPanelSecond = "layout-module--paper-panel-second--NNzcn";
export var paperPanelWrapper = "layout-module--paper-panel-wrapper--6Sc0V";
export var paperWrapper = "layout-module--paper-wrapper--ENrT0";
export var signupButton = "layout-module--signup-button--jmiyR";
export var signupSection = "layout-module--signup-section--CBIqr";
export var smallPanel = "layout-module--small-panel--1l1yH";
export var subtitle = "layout-module--subtitle--uN9B6";
export var tallButton = "layout-module--tall-button--QqFe7";
export var topContent = "layout-module--top-content--jxt0v";
export var topContentWrapper = "layout-module--top-content-wrapper--KQSc7";