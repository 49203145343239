// extracted by mini-css-extract-plugin
export var buttonWrapper = "companies-module--button-wrapper--k+NSQ";
export var closeButton = "companies-module--close-button--xx2NF";
export var column1 = "companies-module--column1--TiSMx";
export var column2 = "companies-module--column2--RsLj7";
export var column3 = "companies-module--column3--3k1xv";
export var column4 = "companies-module--column4--SoQKC";
export var companies = "companies-module--companies--Lkti6";
export var description = "companies-module--description--b4yqy";
export var descriptionLabel = "companies-module--description-label--W5WLS";
export var descriptionToggle = "companies-module--description-toggle--GIy0C";
export var descriptionWrapper = "companies-module--description-wrapper--ILyfS";
export var downloadButton = "companies-module--download-button--F6IhD";
export var downloadLink = "companies-module--download-link--8Hwdm";
export var label = "companies-module--label--LMh+W";
export var labelSub = "companies-module--label-sub--C4vor";
export var oneCol = "companies-module--one-col--HFw+g";
export var sliderContent = "companies-module--slider-content--8EEFk";
export var sliderNext = "companies-module--slider-next--XXY4Y";
export var sliderPrev = "companies-module--slider-prev--fISPR";
export var sliderWrapper = "companies-module--slider-wrapper--EE3ZJ";
export var tableHeader = "companies-module--table-header--Arlk7";
export var tableIcon = "companies-module--table-icon--9mvMG";
export var tableSubtitle = "companies-module--table-subtitle--4YyXL";
export var tableTitle = "companies-module--table-title--VxpXY";
export var twoCol = "companies-module--two-col--EcXnl";